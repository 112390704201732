<template>
  <div class="container">
    <Count></Count>
    <el-row class="header">
      <el-col :span="24"></el-col>
    </el-row>
    <el-row class="title">
      <el-col :span="24">
        <h1>肇庆市结核病防治所</h1>
      </el-col>
    </el-row>
    <el-row class="main">
      <el-col :span="12" @click.native="dayRegister">
        <div class="rowM a">
          <img alt="当天挂号" src="@/assets/mzgh.png" />
          <h3>门诊挂号</h3>
        </div>
      </el-col>
      <el-col :span="12" @click.native="register">
        <div class="rowM n">
          <img alt="预约挂号" src="../assets/yygh.png" />
          <h3>预约挂号</h3>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" @click.native="outpatient">
        <div class="rowM c">
          <img alt="门诊缴费" src="../assets/mzjf.png" />
          <h3>门诊缴费</h3>
        </div>
      </el-col>
      <el-col :span="12" @click.native="inspection">
        <div class="rowM e">
          <div>
            <img alt="ceshi" src="../assets/menzhen.png" />
          </div>
          <h3>检验报告</h3>
        </div>
      </el-col>
      <!-- <el-col :span="12" @click.native="report">
        <div class="rowM d">
          <img alt="检查报告" src="../assets/check.png" />
          <h3>检查报告</h3>
        </div>
      </el-col> -->
    </el-row>
    <el-row>
    <el-col :span="12" @click.native="degree">
      <div class="rowM l">
        <img alt="满意度调查" src="../assets/file.png" />
        <h3>满意度调查</h3>
      </div>
    </el-col>
      <el-col :span="12">
        <div class="rowM l" @click="inpatientList">
        <img  src="../assets/file.png" alt="住院清单" />
          <h3>住院清单</h3>
        </div>
      </el-col>
<!--    <el-col :span="12" @click.native="file">-->
<!--        <div class="rowM l">-->
<!--          <img alt="建档绑卡" src="../assets/file.png" />-->
<!--          <h3>自助建档</h3>-->
<!--        </div>-->
<!--      </el-col> -->
    </el-row>
    <el-row>

    <el-col :span="12"  >
      <div class="rowM l" @click="inpatient">
        <img  src="../assets/file.png" alt="住院清单" />
        <h3>住院押金</h3>
      </div>
      </el-col>
    </el-row>
    <!--    <el-row>-->
    <!--      <el-col :span="12" @click.native="reg">-->
    <!--        <div class="rowM l">-->
    <!--          <img alt="自助报道" src="../assets/record.png" />-->
    <!--          <h3>预约签到</h3>-->
    <!--        </div>-->
    <!--      </el-col>-->
    <!--    </el-row>-->
    <div v-if="popup" class="mask"></div>
    <div v-if="popup" class="popup">
      <h1>{{ noticeTitle }}</h1>
      <img alt="ceshi" class="plan" src="../assets/plan.jpg" />
      <el-button :disabled="disabled" type="primary" @click.native="ok">关闭</el-button>
      <!-- <el-button type="primary" :disabled="disabled" @click.native="ok">我知道了</el-button> -->
    </div>
    <Foo></Foo>
  </div>
</template>
<script>
import Count from '../components/count'
import Foo from './footer/index'
import { jsonPost, formPost } from '@/baseAPI'
import qs from 'qs'
import api from '@/service/api'

export default {
  components: {
    Foo,
    Count
  },
  data() {
    return {
      popup: false,
      disabled: false,
      DUmsocx1: ''
    }
  },
  created() {
    if (window.localStorage.getItem('cardNo')) {
      const self = this
      setTimeout(() => {
        self.opne()
      }, 6000)
      setTimeout(() => {
        window.localStorage.clear()
        location.reload()
      }, 7000)
    }
  },
  methods: {
    opne() {
      this.$confirm('是否退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '退出成功!'
          })
          window.localStorage.clear()
          location.reload()
        })
        .catch(() => {})
    },
    degree(){
  this.$router.push('/degree')
    },
    getip() {
      const pc = new RTCPeerConnection()

      // 添加一个空的 SDP
      pc.createOffer().then(offer => {
        // 设置本地SDP
        pc.setLocalDescription(offer).then(() => {
          // 从本地SDP中提取IPv4地址
          const ipAddress = pc.localDescription.sdp.match(/(192\.168\.\d{1,3}\.\d{1,3})/)[1]

          console.log('本机IPv4地址是：', ipAddress)
        })
      })
    },
    getDept() {
      let _this = this

      Promise.all([_this.$api.getDept()]).then(data => {
        console.log('data', data)
      })
    },

    async ektjm() {
      let requestData = {
        strUrl: 'https://igb.hsa.gdgov.cn/localcfc/api/hsecfc/localQrCodeQuery',
        inData: {
          orgId: 'H44070500097',
          transType: 'ec.query',
          extra: {},
          data: {
            orgId: 'H44070500097',
            operatorId: '02',
            deviceType: 'SelfService',
            operatorName: '李明',
            businessType: '01101',
            businessInfo: '01',
            officeId: '01',
            officeName: '内科'
          }
        }
      }
      // 将对象转换为表单数据字符串
      const data = new FormData()
      for (const key in requestData) {
        if (requestData.hasOwnProperty(key)) {
          data.append(key, JSON.stringify(requestData[key]))
        }
      }
      console.log(data)
      const res = await api.YbPacs(data)
      console.log(res)
    },
    dayRegister() {
      this.$router.push({
        path: '/login',
        query: {
          name: '当天挂号'
        }
      })
    },
    inspection() {
      this.$router.push({
        path: '/inspection',
        query: {
          name: '检验报告查询'
        }
      })
    },
    list() {
      this.$router.push({
        path: '/login',
        query: {
          name: '缴费列表'
        }
      })
    },
    plan() {
      this.popup = true
    },
    register() {
      this.$router.push({
        path: '/login',
        query: {
          name: '预约挂号'
        }
      })
    },
    outpatient() {
      this.$router.push({
        path: '/login',
        query: {
          name: '门诊缴费'
        }
      })
    },
    report() {
      this.$router.push({
        path: '/inspection',
        query: {
          name: '检查报告'
        }
      })
    },
    pe() {
      this.$router.push({
        path: '/login',
        query: {
          name: '体检报告'
        }
      })
    },
    checkout() {
      let _this = this
      Promise.all([_this.$api.printLis()]).then(data => {})
    },
    order() {
      this.$router.push({
        path: '/registrationList',
        query: {
          name: '挂号订单'
        }
      })
    },
    reg() {
      this.$router.push({
        path: '/login',
        query: {
          name: '自助报道'
        }
      })
    },
    inpatient() {
      this.$router.push({
        path: '/inpatient',
        query: {
          name: '住院按金'
        }
      })
    },
    inpatientList() {
      this.$router.push({
        path: '/inpatientDetail',
        query: {
          name: '住院清单'
        }
      })
    },
    async file() {
      this.$router.push({
        path: '/login',
        query: {
          name: '建档绑卡'
        }
      })
    },
    demo() {
      this.$router.push({
        path: '/demo',
        query: {
          name: '测试'
        }
      })
    },
    ok() {
      this.popup = false
    }
  }
}
</script>
<style lang="less" scoped>
.plan {
  height: 90%;
  width: 100%;
}

.title {
  height: 80px;
  text-align: center;
  letter-spacing: 60px;
  color: white;
  font-size: 50px;
  margin-top: 1%;
}

.main {
  margin: 0 auto;
  margin-top: 3%;
}

.rowM {
  margin: 3% 10%;
  width: 70%;
  height: 300px;
  position: relative;
  display: flex;
  font-size: 60px;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  color: white;

  img {
    height: 150px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.a {
  background-color: #adaa22;
}

.n {
  background-color: #1b7ad6;
}

.c {
  background-color: #44a8bf;
}

.d {
  background-color: #3d963a;
}

.e {
  background-color: #605cba;
}

.l {
  background-color: #0f52a0;
}

.el-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.3;
  z-index: 1;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #dcdfe6;
  width: 90%;
  height: 90%;
  border-radius: 10px;
  padding: 20px 30px;
  z-index: 999;
}

.popup h1 {
  text-align: center;
  letter-spacing: 10px;
  font-size: 50px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.popup .noticeText {
  line-height: 55px;
}

.popup .el-button {
  position: absolute;
  bottom: 50px;
  left: 90%;
  transform: translateX(-50%);
  font-size: 50px !important;
}

.footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}
</style>
